@font-face {
  font-family: "Tiempos Headline";
  src: local("TiemposHeadline-Regular"),
    url("./fonts/TiemposHeadline-Regular.otf") format("opentype");
}

@keyframes slide-up-fade {
  0% {
    opacity: 0;
    top: -10vh;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  scroll-padding-top: 20%;
}

.App {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .App {
    font-size: large;
  }

  .Introduction {
    font-family: Tiempos Headline;
    color: #444;
    position: relative;
    top: -25vh;
    margin-bottom: 0;
  }

  .Introduction h1 {
    padding-right: 40%;
  }

  .Introduction h2 {
    padding-right: 40%;
  }

  .Introduction p {
    padding-right: 40%;
  }

  #profile-img {
    position: absolute;
    top: 0vh;
    right: 10vw;
  }

  #about {
    margin-top: 10%;
    padding-top: 0;
  }

  #tech-list ul {
    column-count: 3;
  }

  #practices-list ul {
    column-count: 2;
  }

  #work {
    background-color: #fffcf7;
  }

  #work h1 {
    font-family: Tiempos Headline;
    animation: fade-in 2s;
  }

  #work ul {
    column-count: 4;
    -webkit-column-count: 4;
  }

  #portfolio-card {
    height: 47rem;
  }

  #portfolio-card h1 {
    font-family: Tiempos Headline;
    font-size: 1rem;
  }
}

.NavBar {
  font-family: Tiempos Headline;
  text-align: center;
  background-color: rgb(246, 241, 235, 0.8);
  color: #444;
  animation: slide-up-fade 1s;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  padding-bottom: 1rem;
  backdrop-filter: blur(10px);
}

.NavLinks {
  font-family: Helvetica;
  font-size: 1rem;
  text-align: center;
}

.EachNavBarLink:link {
  padding: 1.5rem;
  text-decoration: none;
  color: #444;
}

.EachNavBarLink:visited {
  color: #444;
}

.EachNavBarLink:hover {
  color: rgb(136, 136, 136);
}

.Introduction {
  font-family: Tiempos Headline;
  color: #444;
  line-height: 1.5;
  margin-top: 35vw;
}

#profile-img img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border: #444 solid 1px;
  box-shadow: 8px 8px #444;
  animation: fade-in 1.5s;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35%;
}

.Introduction p {
  margin-left: 10%;
  margin-right: 10%;
  font-family: Helvetica;
  text-align: justify;
  animation: fade-in 1.5s;
}

.Introduction h1 {
  margin-left: 10%;
  margin-right: 10%;
  animation: fade-in 2s;
}

.Introduction h2 {
  margin-left: 10%;
  margin-right: 10%;
  animation: fade-in 2s;
}

.Introduction h3 {
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  animation: fade-in 2s;
}

#about {
  font-family: Helvetica;
  line-height: 1.5;
  color: #444;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
  animation: fade-in 2s;
  text-align: justify;
}

#about h1 {
  font-size: 2rem;
  font-family: Tiempos Headline;
  animation: fade-in 2s;
}

#about h2 {
  font-size: 1.5rem;
  text-align: left;
  font-family: Tiempos Headline;
  margin: 0;
  animation: fade-in 2s;
}

#about a {
  color: #444;
}

#about a:visited {
  color: #444;
}

#about a:hover {
  color: rgb(136, 136, 136);
}

#tech-list ul {
  animation: fade-in 2s;
}

#practices-list ul {
  animation: fade-in 2s;
}

#work {
  font-family: Helvetica;
  line-height: 1.5;
  color: #444;
  padding-top: 1rem;
  padding-left: 10%;
  padding-right: 10%;
  animation: fade-in 2s;
  text-align: justify;
}

#work ul {
  padding-left: 0%; /* removes default padding */
}

.project-screenshot {
  height: 200px;
  width: 200px;
  object-fit: cover;
}

#work h1 {
  font-size: 2rem;
  text-align: center;
  font-family: Tiempos Headline;
  animation: fade-in 2s;
}

#portfolio-card {
  list-style: none;
  text-align: center;
  font-size: 1rem;
  background-color: #fffcf7;
  padding: 3%;
  margin-bottom: 5%;
  animation: fade-in 2s;
}

#portfolio-card h1 {
  font-size: 1.25rem;
}

#portfolio-card h2 {
  font-size: 1rem;
}

.project-link-icons {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  animation: fade-in 2s;
}

.project-link-icons:hover {
  opacity: 50%;
}

#back-to-top {
  padding: 1rem;
  color: #444;
  justify-content: center;
  display: flex;
}

#back-to-top:hover {
  color: rgb(136, 136, 136);
}

#back-to-top:visited {
  color: #444;
}

#contact {
  font-family: Tiempos Headline;
  background-color: #fffcf7;
  color: #444;
  text-align: center;
  margin: 10%;
  padding: 5%;
  /* border: #444 solid 1px; */
  animation: fade-in 2s;
}

#contact p {
  font-family: Helvetica;
}

.Social-Icons {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  animation: fade-in 2s;
}

.Social-Icons:hover {
  opacity: 50%;
}

.Footer {
  font-family: Helvetica;
  color: #444;
  text-align: center;
  font-size: small;
  border-top: #444 solid 1px;
  margin-left: 10%;
  margin-right: 10%;
}
